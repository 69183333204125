export default {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://by0c4atwo6.execute-api.eu-west-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_BwLsDAJrD",
        APP_CLIENT_ID: "3ap347e5dr3hire3a9vlbcn0ej",
        IDENTITY_POOL_ID: "eu-west-1:8eb07b70-6fad-4e2e-917e-ada8648e86c2"
    }
};