import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import './LocationMap.css';
import axios from 'axios'
import redPin from '../assets/images/redPin.png';
import bluePin from '../assets/images/bluePin.png';
import purplePin from '../assets/images/purplePin.png';
import greenPin from '../assets/images/greenPin.png';
import yellowPin from '../assets/images/yellowPin.png';
/*
import womanRed from "../assets/images/womanRed.png";
import manRed from "../assets/images/manRed.png";
import tent from "../assets/images/tent.png";
import sleepingBag from "../assets/images/sleepingBag.png";
import womanBlue from "../assets/images/womanBlue.png";
import manBlue from "../assets/images/manBlue.png";*/

const mapStyles = {
    width: '1100px',
    height: '700px',
};

class LocationMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            done: false,
            dataToDownload: [],
            downLoadError: false,
            stores: [{latitude: 53.3541175, longitude: -6.2499196},
                {latitude: 47.359423, longitude: -122.021071},
                {latitude: 47.2052192687988, longitude: -121.988426208496},
                {latitude: 47.6307081, longitude: -122.1434325},
                {latitude: 47.3084488, longitude: -122.2140121},
                {latitude: 47.5524695, longitude: -122.0425407}],
            locations: [],
            locationMode: "lastnight",
            showInfoWindow: false,
            infoWindowLatitude: 0.0,
            infoWindowLongitude: 0.0,
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false
        };
    }

    componentDidMount() {
        this.getMapsLocations("lastnight");
    }

    getMapsLocations(mode) {
        console.log("getMapsLocation called");
        let oldRecords = this.state.locations;
        this.setState({done: false, downLoadError: false});
        axios.post(`https://www.scoilapp.com/v1/get-locations`, { "james" : "dp6734;*Gd%%lLLdjds332aS" , "mode" : mode }
            , {
                headers: {
                    'Authorization': this.props.location.token
                }
            })
            .then(res => {
                console.log(res);
                console.log(res.data);

                if (res.data.hasOwnProperty('errorMessage')){
                    console.log("Error caught: ");
                    console.log(res.data.errorMessage);
                    this.setState({locations: oldRecords, done: true, downLoadError: true , locationMode: mode})
                }else{
                    let newRecords = res.data;
                    this.setState({locations: newRecords, done: true, downLoadError: false , locationMode: mode})
                }
            })
            .catch(error => {
                console.log("getMapsLocations error:" + error);
                this.setState({done: true})
            })
    }

    displayMarkers = () => {
        return this.state.locations.map((store, index) => {
            var iconToUse = `${redPin}`;
            /*if (store.route === 'Mobile (Van)'){
                iconToUse = `${purplePin}`;
            }else if (store.route === 'Northside 1'){
                iconToUse = `${redPin}`;
            }else if (store.route === 'Northside 2'){
                iconToUse = `${orangePin}`;
            }else if (store.route === 'south 1'){
                iconToUse = `${bluePin}`;
            }else if (store.route === 'south 2'){
                iconToUse = `${navyPin}`;
            }*/
            if (store.gender === 'm help needed') {
                iconToUse = `${redPin}`;
            } else if (store.gender === 'f help needed') {
                iconToUse = `${purplePin}`;
            } else if (store.gender === 't') { //tent
                iconToUse = `${greenPin}`;
            } else if (store.gender === 's') { //tent
                iconToUse = `${yellowPin}`;
            } else {
                iconToUse = `${bluePin}`;
            }
            return <Marker key={index} id={index} position={{
                lat: store.latitude,
                lng: store.longitude
            }}
                           name={store.message}
                           onClick={this.onMarkerClick

                           }
                           options={{icon:`${iconToUse}`}} />
        })
    };

    onMarkerClick = (props, marker) =>
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
        });

    onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });

    onMapClicked = () => {
        if (this.state.showingInfoWindow)
            this.setState({
                activeMarker: null,
                showingInfoWindow: false
            });
    };

    goToTable() {
        this.props.history.push(
            {
                pathname:"/table",
                token: this.props.location.token}
        );
    }

    goToManaulEntry() {
        this.props.history.push(
            {
                pathname:"/manual",
                token: this.props.location.token}
        );
    }

    render() {
        var errorMessageToShow = "";
        if (this.state.downLoadError) {
            errorMessageToShow = <p>Locations retrieval error - please try again </p>;
        } else {
            errorMessageToShow = <p>Locations retrieval successful: {this.state.locations.length} Records Retrieved </p>;
        }
        if (this.state.done) {
            return (
                <div>
                    <div className="headerButtons">
                        <button className="headerButton buttonStyling" onClick={() => this.goToTable()}>Table</button>
                    </div>
                    <div className="col-md-12 center">
                        {errorMessageToShow}
                        <p></p>
                        <button className="btn btn-default borderColor buttonMargins"
                                onClick={() => this.getMapsLocations("lastnight")}>All data from 00:00 am yesterday onwards
                        </button>

                        <img src={purplePin} alt="Red woman" className="icons"/>Female in need of help
                        <img src={redPin} alt="Red man" className="icons"/>Male in need of help

                        <img src={greenPin} alt="Tent" className="tent" />Tent
                        <img src={yellowPin} alt="Sleeping bag" className="sleepingBag"/>    Sleeping Bag
                        <br/>

                        <button className="btn btn-default borderColor buttonMargins"
                                onClick={() => this.getMapsLocations("lastweek")}>Last 7 days
                        </button>
                        <button className="btn btn-default borderColor buttonMargins"
                                onClick={() => this.getMapsLocations("last30days")}>Last 30 days
                        </button>

                        <button className="btn btn-default borderColor buttonMargins"
                                onClick={() => this.getMapsLocations("alldata")}>All data
                        </button>

                        <img src={bluePin} alt="Red woman" className="icons"/>Male/Female not in need of help

                        <br/><br/>

                        <Map
                            google={this.props.google}
                            zoom={12}
                            style={mapStyles}
                            initialCenter={{lat: 53.3472569, lng: -6.2614301}}
                        >
                            {this.displayMarkers()}
                            <InfoWindow
                                marker={this.state.activeMarker}
                                onClose={this.onInfoWindowClose}
                                visible={this.state.showingInfoWindow}
                            >
                                <div>
                                    <h4>{this.state.selectedPlace.name}</h4>
                                </div>
                            </InfoWindow>
                        </Map>

                        <br/><br/><br/><br/>
                    </div>
                </div>


            );
        } else {
            return (
                <div>
                    Data loading ...
                </div>
            );
        }
    }
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyCbN592zVMQIT6PXswTQM5cbF1REGVpvKM'
})(LocationMap);

