import React, {Component} from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from 'axios'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {CSVLink} from 'react-csv'
import './ExportReactCSV.css'
import "./Table.css";
import {withRouter} from 'react-router-dom';
//import { Auth } from "aws-amplify";

const changeDateFormat = (date) => {
    try {
        let yyyy = "";
        let mm = "";
        let dd = "";
        let hh = "";
        let mi = "";
        let ss = "";

        [yyyy, mm, dd, hh, mi, ss] = date.split(/[/:\-T]/);
        return dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + mi + ':' + ss.substring(0, 2);
    }catch(e){
        console.log(e);
        return date;
    }
}

const columns = [{
    Header: '#',
    accessor: 'pid', // String-based value accessors!
    Cell: props => <span className='number'>{props.value}</span>,
    width: 70
    },
    {
        Header: 'Created',
        accessor: 'isoAttribute',
        Cell: props => <span className='number'>{changeDateFormat(props.value)}</span> ,
        width: 180
    },
    {
    Header: 'Volunteer',
    accessor: 'president',
    Cell: props => <span className='number'>{props.value.charAt(0).toUpperCase() + props.value.substring(1)}</span> ,
    width: 70
}, {
    Header: 'Message',
    accessor: 'message',
    Cell: props => <span className='number'>{props.value}</span> ,
    width:350,
    style:{ 'white-space': 'unset'}
},{
    Header: 'Gender',
    Cell: props => <span className='number'>{props.value}</span> ,
    accessor: 'gender',
    width: 140
}, {
    Header: 'Latitude',
    accessor: 'latitude', // String-based value accessors!
    Cell: props => <span className='number'>{props.value}</span> ,
    width: 170
}, {
    Header: 'Longitude',
    accessor: 'longitude', // String-based value accessors!
    Cell: props => <span className='number'>{props.value}</span> ,
    width: 170
}, {
    Header: 'Address',
    accessor: 'address',
    Cell: props => <span className='number'>{props.value}</span> ,
    width:350,
    style:{ 'white-space': 'unset'}
}, {
    Header: 'Route',
    Cell: props => <span className='number'>{props.value}</span> ,
    accessor: 'route',
    width: 100
}];

class Table extends Component {
    constructor(props) {
        super(props);
        this.download = this.download.bind(this);
        this.state = {
            records: [],
            done: false,
            dataToDownload: [],
            downLoadError: false,
            locationMode: "lastnight"
        };
        this.cancel = null;
    }

    download() {
        console.log("download called");
        const currentRecords = this.reactTable.getResolvedState().sortedData;
        var data_to_download = []
        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {}
            for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
                record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
            }
            data_to_download.push(record_to_download)
        }
        this.setState({ dataToDownload: data_to_download }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLink.link.click()
        })
    }

    downloadPDF(){
        console.log("download pdf called");
        const currentRecords = this.reactTable.getResolvedState().sortedData;
        var data_to_download = []
        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {}
            for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
                record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
            }
            data_to_download.push(record_to_download)
        }
        this.setState({ dataToDownload: data_to_download }, () => {
            // click the CSVLink component to trigger the CSV download
            this.exportPDF();
        })
    }

    componentDidMount() {
        this.getLocations("lastnight");
       // this.getNewLocations("lastnight");
    }

    getLocations(mode) {
        console.log("getLocations called");
        /*try {
            console.log("this.props.location.token: " + this.props.location.token);
        } catch (e) {
            console.log("this.props.location.token error: " + e);
        }*/

        try{
            let oldRecords = this.state.records;
            this.setState({done: false, downLoadError: false});
            axios.post(`https://www.scoilapp.com/v1/get-locations`, { "james" : "dp6734;*Gd%%lLLdjds332aS" , "mode" : mode }
                , {
                    headers: {
                        'Authorization': this.props.location.token
                    }
                })
                .then(res => {
                    console.log(res);
                    console.log(res.data);

                    if (res.data.hasOwnProperty('errorMessage')){
                        console.log("Error caught: ");
                        console.log(res.data.errorMessage);
                        this.setState({records: oldRecords, done: true, downLoadError: true , locationMode: mode})
                    }else{
                        let newRecords = res.data;

                       // newRecords.sort((a, b) => a.created.localeCompare(b.created));


                        this.setState({records: newRecords, done: true, downLoadError: false , locationMode: mode})
                    }
                })
                .catch(error => {
                    console.log("getLocations error:" + error);
                    this.setState({done: true, downLoadError: true})
                })
        }catch(e){
            console.log("Data retrieval failed");
            this.setState({done: true, downLoadError: true})
        }

    }

    componentWillUnmount() {
        // this.cancel()
    }

    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        var date = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year
        const currentDate = date + "/" + month + "/" + year;


        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Streetlink Records";
        const headers = [["Created", "Gender", "Latitude", "Longitude", "Address", "Route"]];

        const data = this.state.dataToDownload.map(elt=> [elt.Created, elt.Gender, elt.Latitude, elt.Longitude, elt.Address, elt.Route]);

        let content = {
            startY: 50,
            head: headers,
            body: data,
            headerStyles: {
                fillColor: [144, 194, 73],
                fontSize: 11
            }
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Streetlink_report_" + currentDate + ".pdf")
    };

    goToMap() {
        this.props.history.push(
            {
                pathname:"/map",
                token: this.props.location.token}
        );
    }

    goToNewMap() {
        this.props.history.push(
            {
                pathname:"/newmap",
                token: this.props.location.token}
        );
    }

    goToManaulEntry() {
        this.props.history.push(
            {
                pathname:"/manual",
                token: this.props.location.token}
        );
    }


  render() {
      var errorMessageToShow = "";
      if (this.state.downLoadError) {
          errorMessageToShow = <p>Locations retrieval error - please try again </p>;
      } else {
          errorMessageToShow = <p>Locations retrieval successful </p>;
      }

      if (this.state.done) {
          return (
              <div>
                  <div className="headerButtons">
                      <button className="headerButton buttonStyling" onClick={() => this.goToMap()}>Map</button>
                  </div>
                  <div className="col-md-12 center">
                      {errorMessageToShow}
                      <button className="btn btn-default borderColor buttonMargins" onClick={ () => this.getLocations("lastnight")}>All data from 00:00 am yesterday onwards</button>
                      <button className="btn btn-default borderColor buttonMargins" onClick={ () => this.getLocations("lastweek")}>Last 7 days</button>
                      <button className="btn btn-default borderColor buttonMargins" onClick={ () => this.getLocations("last30days")}>Last 30 days</button>
                      <button className="btn btn-default borderColor buttonMargins" onClick={ () => this.getLocations("alldata")}>All data</button>

                      <button className="btn btn-default pdf buttonMargins" onClick={ () => this.download()}>
                          Download to CSV
                      </button>
                      <CSVLink
                          data={this.state.dataToDownload}
                          filename="data.csv"
                          className="hidden"
                          ref={(r) => this.csvLink = r}
                          target="_blank"/>
                      <br></br><br></br>
                  </div>

                  <ReactTable
                      ref={(r) => this.reactTable = r}
                      filterable={true}
                      data={this.state.records}
                      columns={columns}
                      defaultSorted={[
                          {
                              id: "created",
                              desc: true
                          }
                      ]}
                      className="-striped -highlight borderColor tableCorners"
                  >
                      {(state, makeTable, instance) => {
                          let recordsInfoText = "";

                          const { filtered, pageRows, pageSize, sortedData, page } = state;

                          if (sortedData && sortedData.length > 0) {
                              let isFiltered = filtered.length > 0;
                              let totalRecords = sortedData.length;
                              let recordsCountFrom = page * pageSize + 1;
                              let recordsCountTo = recordsCountFrom + pageRows.length - 1;
                              if (isFiltered)
                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                              else
                                  recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
                          } else recordsInfoText = "No records";
                          return (
                              <div className="main-grid">
                                  <div className="above-table text-right">
                                      <div className="col-sm-12">
                                          <span className="records-info">{recordsInfoText}</span>
                                      </div>
                                  </div>
                                  {makeTable()}
                              </div>
                          );
                      }}
                  </ReactTable>
              </div>
          );
      } else {
          return (
              <div>
                  Data loading ...
              </div>
          );
      }
  }

}



export default withRouter(Table);

/*<Records records={this.state.records}/>
* <ExportReactCSV csvData={} fileName={this.state.fileName}/>*/
