import './ManualEntry.css';
import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Login.css";
import LoaderButton from "../components/LoaderButton";
import axios from 'axios';

export default class ManualEntry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            males: null,
            females: null,
            date: null,
            routeName: "Mobile Van",
            success: false,
            loadingMessage: ""
        };
    }

    postCountsToDB() {
        axios.post(`https://www.scoilapp.com/v1/post-counts`, { "james" : "fdvdsbfvGGDF4564Dsdfsd43£$33" , "route" : this.state.routeName
            , "male": this.state.males, "females": this.state.females, "date" : this.state.date  })
            .then(res => {
                //console.log(res);
                //console.log(res.data);

                if (res.data.hasOwnProperty('errorMessage')){
                    //console.log("Error caught: ");
                    //console.log(res.data.errorMessage);
                    this.setState({isLoading: false, success: false, loadingMessage: "Error - please try again or contact support."});
                }else{
                    //console.log("all good in the hood");
                    this.setState({isLoading: false, success: true, loadingMessage: "Success", males: "0", females: "0"});
                }
            })
    }

    validateForm() {
        return (this.state.males > 0 || this.state.females > 0) && this.state.date;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        if (this.validateForm()) {
            this.setState({isLoading: true});
            this.postCountsToDB();
        } else{
            //console.log("seriously?");
        }
    };

    goToTable() {
        this.props.history.push(
            {
                pathname:"/table",
                token: this.props.location.token}
        );
    }

    goToMap() {
        this.props.history.push(
            {
                pathname:"/map",
                token: this.props.location.token}
        );
    }

    render() {
        return (
            <div className="Login">
                <div className="headerButtons">
                    <button className="headerButton buttonStyling" onClick={() => this.goToTable()}>Table</button>
                    <button className="buttonStyling" onClick={() => this.goToMap()}>Map</button>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <h4>Use this form to submit manual counts</h4>
                    <FormGroup controlId="males" bsSize="large">
                        <ControlLabel>Males</ControlLabel>
                        <FormControl
                            autoFocus
                            type="number"
                            value={this.state.males}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="females" bsSize="large">
                        <ControlLabel>Females</ControlLabel>
                        <FormControl
                            value={this.state.females}
                            onChange={this.handleChange}
                            type="number"
                        />
                    </FormGroup>
                    <FormGroup controlId="routeName">
                        <ControlLabel>Select Route</ControlLabel>
                        <FormControl
                            name="routeName"
                            componentClass="select"
                            onChange={this.handleChange}
                            >
                            <option value="Mobile Van 1">Mobile Van</option>
                            <option value="Northside 1">Northside 1</option>
                            <option value="Northside 2">Northside 2</option>
                            <option value="Southside 1">Southside 1</option>
                            <option value="Southside 2">Southside 2</option>
                        </FormControl>

                    </FormGroup>
                    <FormGroup controlId="date" bsSize="large">
                        <ControlLabel>Date counts are from</ControlLabel>
                        <FormControl
                            value={this.state.date}
                            onChange={this.handleChange}
                            type="date"
                        />
                    </FormGroup>

                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Submit Counts"
                        loadingText="Please wait"
                    />
                    <h4>{this.state.loadingMessage}</h4>
                </form>

            </div>
        );
    }
}
