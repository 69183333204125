import React, { Component } from "react";
import "./Home.css";
import logo from "../assets/images/streetlink.png";
import { withRouter } from 'react-router-dom';

class Home extends Component {

    componentDidMount() {
        console.log("Home props: " +  JSON.stringify(this.props));
    }

    render() {
        return (
            <div className="Home">
                <div className="lander">
                        <img src={logo} alt="Streetlink logo" className="img-responsive"/>
                </div>
            </div>
        );
    }
}

export default withRouter(Home)