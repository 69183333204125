import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import NotFound from "./containers/NotFound";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import LocationMap from "./containers/LocationMap";
import LocationMapNew from "./containers/LocationMapNew";
import Table from "./containers/Table";
import ManualEntry from "./containers/ManualEntry";

export default ({ childProps }) =>
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps} />
        <UnauthenticatedRoute
            path="/login"
            exact
            component={Login}
            props={childProps}
        />
        <UnauthenticatedRoute
            path="/login/reset"
            exact
            component={ResetPassword}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/settings"
            exact
            component={Settings}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/settings/password"
            exact
            component={ChangePassword}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/map"
            exact
            component={LocationMap}
            props={childProps}
        />
            <AuthenticatedRoute
                path="/newmap"
                exact
                component={LocationMapNew}
                props={childProps}
            />
        <AuthenticatedRoute
            path="/table"
            exact
            component={Table}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/manual"
            exact
            component={ManualEntry}
            props={childProps}
        />
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />

    </Switch>;
