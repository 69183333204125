import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Settings.css";

export default class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="Settings">
                <LinkContainer to="/settings/password">
                    <LoaderButton
                        block
                        bsSize="large"
                        text="Change Password"
                    />
                </LinkContainer>
            </div>
        );
    }
}